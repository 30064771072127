<template>
  <div class="pt-3">
    <Loader v-if="!groupUsers || (groupUsers && groupUsers.length == 0)"/>
      <div class="dashboard__container--header">
          <ais-instant-search :search-client="searchClient" index-name="a_users" >
            <ais-search-box placeholder="Add User..." />
            <ais-state-results>
              <template slot-scope="{ state: { query } }">
                <ais-hits v-show="query.length > 0">
                  <template v-slot:item="{ item }">
                    <div>
                      <button @click="addUser(item)" class="btn btn__icon btn__flat mr-4">
                        <i class="fas fa-plus" style="color:blue;" v-if="!performingRequest"></i>
                        <i class="fa fa-spinner fa-spin" style="color:blue;" v-if="performingRequest"></i>
                      </button>
                      <div style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address">{{item.address.city}}, {{item.address.state}} | </span>{{item.email}} | {{item.phone}}</div>
                    </div>
                  </template>
                </ais-hits>
              </template>
            </ais-state-results>
          </ais-instant-search>
         
          <button class="btn btn__small btn__dark mr-3" @click="exportGroup()">export group</button>
      </div>
      <div class="dashboard__container--body pt-3" v-if="groupUsers && groupUsers.length >= 1">

        <UserTable :groupUsers="groupUsers" :group="group" action="removeUser" :showShiftPreference="isNewYork" :isNewYork="isNewYork" />
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
import ExportService from "@/services/ExportService"
import algoliasearch from 'algoliasearch/lite';
import router from '@/router'
import * as moment from 'moment'

export default {
  props: ['group'],
  name: 'group',
  data: () => ({
    performingRequest: false,
    searchClient: algoliasearch(
      '0T1SIY6Y1V',
      'f03dc899fbdd294d6797791724cdb402',
    ),
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'groupUsers']),
    isNewYork() {
      if (this.group && this.group.id && 
        (this.group.id == 'X6d0ja8KTEzrALDX8D5j' || this.group.id == 'KMmMi9tTurFEWG049muQ' || this.group.id == 't9M3jG88eK6RIWPP7JkL' || this.group.id == 'LqkibqVT2aMLBvlzYtDn' || this.group.id == 'Rfq9OFBzRHPRUsLOHBgO')
        ) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    Loader,
    UserTable
  },
  created () {
    if (!this.groupUsers || this.groupUsers.length < 1) {
      this.$store.dispatch("getGroupUsers", this.$route.params.id);
    }
  },
  methods: {
    addUser(item) {
      console.log(item)
      let group = this.group
      this.performingRequest = true;
      this.$store.dispatch("newGroupApplicant", {
        user: item,
        group: this.group
      })
      setTimeout(() => {
        this.performingRequest = false;
        document
        .querySelectorAll('.ais-SearchBox-input')
        .forEach((e) => (e.value = ''))
        document.querySelectorAll('.ais-Hits-item').forEach((e) => e.remove())
      }, 250)
    },
    exportGroup() {
      let logFields = {
          user: this.currentUser.email,
          export: 'Group Export',
          group: this.group.id
      }
      this.$store.dispatch('sendExportLog', logFields)
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "DOB",
        "Skills",
      ]
      const exportItems = [];
      for (var key in this.groupUsers) {

        function mySkillsFunction(groupUsers) {
          let skillsArray = []
          groupUsers[key].skills.forEach(sk => {
            skillsArray.push(sk.title)
          })
          console.log(skillsArray)
          return skillsArray.toString()
        }

        exportItems.push([
          this.groupUsers[key].firstName,
          this.groupUsers[key].lastName,
          this.groupUsers[key].email,
          this.groupUsers[key].phone,
          this.groupUsers[key].dob,
          mySkillsFunction(this.groupUsers)
          
        ])
      }
      console.log(exportItems)
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
  }
}
</script>